import React, { useState } from "react";

import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'

import "./App.css";

const HomePage = () => (
  <Container>
    {/* <Row> */}
    <Row className="align-items-center">
      <Col md={5}>
        <Image src="/photo.jpg" rounded fluid />
      </Col>
      <Col md={7}>
        <div className="main-content">
          <p className="pb-3">I am an Assistant Professor of Economics and Computer Science in the <a href="https://cssh.northeastern.edu/faculty/shuo-zhang/">Department of Economics</a> and <a href="https://www.khoury.northeastern.edu/people/shuo-zhang/">Khoury College of Computer Sciences</a> at the Northeastern University. My research interest is in labor economics, platform design and algorithmic fairness.</p>
          <p className="pb-3"><span><b>Research Fields</b></span>: Labor Economics, Behavioral Economics, Applied Econometrics</p>
          <p className="mb-1"><span><b>Email</b>:{' '}<a target="_blank" rel="noopener noreferrer" href="mailto:s.zhang@northeastern.edu" className="main-link" style={{ textDecoration: 'none' }}>s.zhang [at] northeastern [dot] edu</a></span></p>
          <p className="mb-1"><Image src="/github-mark.png" width={20} rounded fluid /><span className="ml-2"><a target="_blank" rel="noopener noreferrer" href="https://github.com/shuo-zhang-ucsb" className="main-link" style={{ textDecoration: 'none' }}>github/shuo-zhang-ucsb</a></span></p>
        </div>
      </Col>
    </Row>

  </Container>
);

const ResearchPage = () => (
  <div>
    <div className="mt-5">
      <h3 className="section-title">Publications</h3>
      <div className="mt-4">
        <h5 className="paper-title"><a target="_blank" rel="noopener noreferrer" href="https://doi.org/10.1016/j.jdeveco.2020.102531" className="paper-link" style={{ textDecoration: 'none' }}>Gender-targeted Job Ads in the Recruitment Process: Facts from a Chinese Job Board</a></h5>
        <p className="mt-2 paper-author">With <a target="_blank" rel="noopener noreferrer" href="https://sites.google.com/view/peter-kuhn/home" style={{ textDecoration: 'none' }}>Peter Kuhn</a> and <a target="_blank" rel="noopener noreferrer" href="https://cbe.anu.edu.au/about/staff-directory/dr-kailing-shen" style={{ textDecoration: 'none' }}>Kailing Shen</a>, Journal of Development Economics 147 (2020): 102531</p>
      </div>
      <div className="mt-4">
        <h5 className="paper-title"><a target="_blank" rel="noopener noreferrer" href="https://doi.org/10.1016/j.socec.2020.101536" className="paper-link" style={{ textDecoration: 'none' }}>Words Matter Life: The Effect of Language on Suicide Behavior</a></h5>
        <p className="mt-2 paper-author">With Donald Lien, Journal of Behavioral and Experimental Economics 86 (2020): 101536</p>
      </div>
    </div>
    <div className="mt-5">
      <h3 className="section-title">Working Papers</h3>
      <div className="mt-4">
        <h5 className="paper-title">Measuring Algorithmic Bias in Job Recommender Systems: An Audit Study Approach</h5>
        <p className="mt-2 paper-author">With <a target="_blank" rel="noopener noreferrer" href="https://sites.google.com/view/peter-kuhn/home" style={{ textDecoration: 'none' }}>Peter Kuhn</a></p>
        <div className="mt-3 paper-abstract">
          <p>
            To social science researchers, the recommendation algorithms used by job boards to recommend jobs to workers are a proprietary 'black box'. To derive insights into how these algorithms work, we conduct an algorithmic audit of four Chinese job boards, where we create fictitious applicant profiles and observe which jobs are recommended to profiles that differ only in age and gender. We then estimate the cumulative effect of pursuing these recommended jobs by applying to them in up to three rounds of successive applications. Focusing on the jobs that were recommended to just one of the two genders that applied, we find that only-to-women jobs propose lower wages, request fewer years of working experience, and are more likely to require literacy skills and administrative skills. Only-to-women (men) jobs also disproportionately contain words related to feminine (masculine) personality characteristics, as measured by three distinct approaches for identifying such characteristics. Finally, we assess the patterns in the recommendations generated by our audit study for their consistency with four processes the algorithms could be using: item-based collaborative filtering, content-based matching, matching based on recruiters' profile views, and rules-based matching based on employers' stated gender preferences. We find evidence suggesting that the algorithms are relying on all but the last of these processes.
          </p>
        </div>
      </div>
      <div className="mt-4">
        <h5 className="paper-title">Should I Show or Should I Hide - When Do Jobseekers Reveal Their Wages?</h5>
        <p className="mt-2 paper-author">With <a target="_blank" rel="noopener noreferrer" href="https://sites.google.com/view/peter-kuhn/home" style={{ textDecoration: 'none' }}>Peter Kuhn</a>, Taoxiong Liu and Kebin Dai</p>
        <div className="mt-3 paper-abstract">
          <p>
            This paper investigates the role of workers’ voluntary wage disclosure in the job search process. Using internal data from an online job board, we find that on average, workers' disclosure decisions are consistent with a model in which high current wages are seen as "good news" by prospective employers:  Workers are more likely to disclose their wages when those wages are higher than might be expected, based on the worker's resume and application behavior. Employers' responses to workers' resumes, however, are hard to reconcile with these disclosure patterns:  While employers do respond positively to workers with higher current wages, they do so equally, regardless of whether the worker has disclosed her wages. This suggests that firms can infer the unobserved ability associated with a worker's current wages from other aspects of her resume and application behavior.  Further, disclosing one's current wage, regardless of its level, appears to reduce firm's interests in hiring that worker.
          </p>
        </div>
      </div>
      <div className="mt-4">
        <h5 className="paper-title">Crisis at 35: Middle Ageism in the Labor Market</h5>
        <p className="mt-2 paper-author">With <a target="_blank" rel="noopener noreferrer" href="https://www.lizi-yu.com/" style={{ textDecoration: 'none' }}>Lizi Yu</a>, Simin Yuan, Taoxiong Liu and Kebin Dai</p>
        <div className="mt-3 paper-abstract">
          <p>
            This paper investigates age-based hiring bias among recruiters, leveraging 14 million applications from a leading online job platform in China. We estimate age bias among more comparable cohorts. We find robust evidence of age bias in hiring, with a subtle decline in success rates after age 30, and this “middle-ageism” effect becomes pronounced for applicants aged 35 to 50. Age bias is pervasive across industries and occupations, disproportionately affecting female applicants more than their male peers.
          </p>
        </div>
      </div>
      <div className="mt-4">
        <h5 className="paper-title">The Invisible Host: Race and Identifiability of Hosts on Airbnb</h5>
        <p className="mt-2 paper-author">With <a target="_blank" rel="noopener noreferrer" href="https://sites.google.com/view/shariqmohammed/" style={{ textDecoration: 'none' }}>Shariq Mohammed</a> and Yanli Liu</p>
        <div className="mt-3 paper-abstract">
          <p>
            This study investigates racial dynamics on digital platforms, focusing on Airbnb, by examining users' strategic decisions to disclose their racial identity through profile pictures. Spanning 22 U.S. cities over 2 years and creating a novel panel of host profile photos, we find that Black hosts are more likely to conceal their identity. Additionally, we apply these insights to reevaluate racial price gap estimates in existing literature, considering both identifiable and unidentifiable profile pictures. Our analysis is further enriched by an experimental survey designed to assess the impact of racial representation in host profiles on guest preferences. The results reveal a significant racial bias against Black hosts, but this gap diminishes when hosts possess positive attributes.
          </p>
        </div>
      </div>
      <div className="mt-4">
        <h5 className="paper-title">TwoWayFEWeights: Estimation of the Weights Attached to the Two-Way Fixed Effects Regressions</h5>
        <p className="mt-2 paper-author">With <a target="_blank" rel="noopener noreferrer" href="https://sites.google.com/site/clementdechaisemartin/" style={{ textDecoration: 'none' }}>Clément de Chaisemartin</a></p>
        <div className="mt-3 paper-abstract">
          <p>
            Linear regressions with period and group fixed effects are widely used to estimate treatment effects in social and environmental sciences. However, those regressions estimate weighted sums of the treatment effect in every treated group×period, potentially with some negative weights. Owing to the negative weights, the treatment coefficient in those regressions may for instance be negative while the treatment effect is positive in every group×period (de Chaisemartin and D’Haultfœuille, 2020). This paper presents an R package, TwoWayFEWeights, that enables researchers to compute the weights attached to their regression, and assess its robustness. We also use TwoWayFEWeights to revisit an empirical application.
          </p>
        </div>
      </div>
    </div>
    <div className="mt-5">
      <h3 className="section-title">Work In Progress</h3>
      <div className="mt-4">
        <h5 className="paper-title" style={{ display: 'inline' }}>Congestion, Competition and Matching in Online Job Boards, </h5>
        <span className="mt-2 paper-author">with Taoxiong Liu and Simin Yuan</span>
      </div>
      <div className="mt-4">
        <h5 className="paper-title" style={{ display: 'inline' }}>How Does US-China Trade War Affect the Middle- and High-income Employment in China? </h5>
        <span className="mt-2 paper-author">with <a target="_blank" rel="noopener noreferrer" href="https://www.lizi-yu.com/" style={{ textDecoration: 'none' }}>Lizi Yu</a></span>
      </div>
      <div className="mt-4">
        <h5 className="paper-title" style={{ display: 'inline' }}>From Addiction to Aggression: The Spillover Effects of Opioid Policies on Intimate Partner Violence, </h5>
        <span className="mt-2 paper-author">with <a target="_blank" rel="noopener noreferrer" href="https://faculty.bentley.edu/profile/ddave" style={{ textDecoration: 'none' }}>Dhaval Dave</a>, <a target="_blank" rel="noopener noreferrer" href="https://www.bilgeerten.com/" style={{ textDecoration: 'none' }}>Bilge Erten</a>, and <a target="_blank" rel="noopener noreferrer" href="https://pinarkeskin.com/" style={{ textDecoration: 'none' }}>Pinar Keskin</a></span>
      </div>
      <div className="mt-4">
        <h5 className="paper-title" style={{ display: 'inline' }}>Policy Shock and On-the-Job Search: Evidence from China, </h5>
        <span className="mt-2 paper-author">with <a target="_blank" rel="noopener noreferrer" href="https://www.lizi-yu.com/" style={{ textDecoration: 'none' }}>Lizi Yu</a> and Taoxiong Liu</span>
      </div>
      <div className="mt-4">
        <h5 className="paper-title" style={{ display: 'inline' }}>Air Pollution and Job Search Behaviors, </h5>
        <span className="mt-2 paper-author">with <a target="_blank" rel="noopener noreferrer" href="https://www.lizi-yu.com/" style={{ textDecoration: 'none' }}>Lizi Yu</a> and Peng Zhang</span>
      </div>
      <div className="mt-4">
        <h5 className="paper-title" style={{ display: 'inline' }}>Modeling the Dynamics of Seller Retaliation on Amazon, </h5>
        <span className="mt-2 paper-author">with <a target="_blank" rel="noopener noreferrer" href="https://jlgleason.github.io/" style={{ textDecoration: 'none' }}>Jeffrey Gleason</a> and <a target="_blank" rel="noopener noreferrer" href="https://cbw.sh/" style={{ textDecoration: 'none' }}>Christo Wilson</a></span>
      </div>
    </div>
  </div >
);

const TeachingPage = () => (
  <div>
    <div className="mt-5">
      <p className="mt-1 main-content">DS2001: Data Science Practicum</p>
    </div>
  </div>
);

const CvPage = () => (
  <div>
    <div className="mt-1" height="100%">
      <object data="/cv.pdf#view=FitH" type="application/pdf" width="100%" height="100%" style={{ minHeight: "80vh" }}></object>
    </div>
  </div>
)

const SwitchPage = (props) => {
  return (
    <>
      {(props.page === "home") && <HomePage />}
      {(props.page === "research") && <ResearchPage />}
      {(props.page === "teaching") && <TeachingPage />}
      {(props.page === "cv") && <CvPage />}
    </>
  );
};

const App = () => {
  const [page, setPage] = useState("home");

  return (
    <Container className="d-flex p-4 mx-auto flex-column">
      <header><h1 className="header"><a href="/" style={{ textDecoration: 'none' }}><span className="title">Shuo Zhang</span></a></h1></header>
      <Navbar bg="white" expand="sm" className="navbar-expand">
        <Container>
          {/* <Navbar.Brand /> */}
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
            <Nav
              className="justify-content-center float-md-end"
              onSelect={(selectedKey) => setPage(selectedKey)}
            >
              <Nav.Item>
                <Nav.Link eventKey="home">HOME</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="research">RESEARCH</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="teaching">TEACHING</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="cv">VITAE</Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Jumbotron className="bg-white">
        <SwitchPage page={page} />
      </Jumbotron>
    </Container>
  );
};

export default App;
